<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('actions.inconsistency.fixInconsistency')"
			:modelName="modelName"
			:dialogName="inconsistencyFixInconsistency"
			:onOk="onOkFixInconsistency"
			:onShow="onShowFixInconsistency"
		>
			<template slot="message">
				<v-row dense>{{ $t('actions.inconsistency.fixInconsistencyDesc') }} </v-row>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'inconsistency-modals',
	data() {
		return {
			inconsistencyFixInconsistency: 'fixInconsistency'
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onOkFixInconsistency(modalData) {
			console.log(modalData);
			const params = {
				pk: modalData.id
			};
			const url = this.$store.getters.getModelByName(this.modelName).url.fixInconsistency;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.inconsistency.sampleaction.title');
			return new Promise((resolve) => {
				this.$puiRequests.getRequest(
					url,
					params,
					(response) => {
						// Valid response, do code and close modal
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);

						console.log(response);
						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);

						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title);
						resolve(false);
					},
					null
				);
			});
		},
		onShowFixInconsistency(modalData) {
			// Put here code to execute before open modal.
			// Examples:
			// - Watch pui-select/pui-datatable with events
			// - change values or set new values "this.$set(modalData,'field3', null)"
			// ...
			console.log(modalData);
		}
	}
};
</script>
