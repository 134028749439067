<template>
	<div class="pui-form">
		<inconsistency-modals :modelName="modelName"></inconsistency-modals>

		<v-form class="mb-4 pb-4 mt-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<!-- TYPEID -->
						<v-col cols="4">
							<pui-select
								:id="`typeid-${modelName}`"
								:attach="`typeid-${modelName}`"
								:label="$t('form.inconsistency.typeid')"
								toplabel
								clearable
								required
								:disabled="true"
								v-model="model"
								modelName="inconsistencytypepa"
								:itemsToSelect="itemsToSelectTypeid"
								:modelFormMapping="{ inconsistencytypeid: 'typeid' }"
								itemValue="inconsistencytypeid"
								itemText="title"
								reactive
							></pui-select>
						</v-col>
						<v-col cols="4" class="mt-4">
							<pui-checkbox
								:id="`fixed-inconsistency`"
								:label="$t('form.inconsistency.fixed')"
								:true-value="true"
								v-model="model.fixed"
								:false-value="false"
								:disabled="formDisabled"
							></pui-checkbox>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="4">
							<pui-select
								:id="`visit-${modelName}`"
								:attach="`visit-${modelName}`"
								:label="$t('form.inconsistency.visit')"
								toplabel
								clearable
								required
								:disabled="true"
								v-model="model"
								modelName="visit"
								:itemsToSelect="[{ id: model.visit }]"
								:modelFormMapping="{ id: 'visit' }"
								itemValue="id"
								itemText="portcallnumber"
								reactive
								detailComponentName="visit-form"
								detailModelName="visit"
							></pui-select>
						</v-col>
						<v-col cols="4">
							<pui-select
								:id="`stopid-inconsistency`"
								:attach="`stopid-inconsistency`"
								:label="$t('form.inconsistency.stopid')"
								toplabel
								clearable
								:disabled="true"
								v-model="model"
								modelName="stop"
								:itemsToSelect="[{ id: model.stopid }]"
								:modelFormMapping="{ id: 'stopid' }"
								:itemValue="['id']"
								:itemText="(item) => getStopItemText(item)"
								:order="{ num: 'asc' }"
								reactive
								required
								detailComponentName="stop-form"
								detailModelName="stop"
							></pui-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col cols="8">
							<pui-text-field
								:id="`description-inconsistency`"
								v-model="model.description"
								:label="$t('form.inconsistency.description')"
								:disabled="formDisabled"
								required
								toplabel
								maxlength="300"
							></pui-text-field>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import inconsistencyActions from './InconsistencyActions';
import inconsistencyModals from './InconsistencyModals.vue';

export default {
	name: 'inconsistency-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'inconsistency-modals': inconsistencyModals
	},
	data() {
		return {
			modelName: 'inconsistency',
			actions: inconsistencyActions.formActions
		};
	},
	methods: {
		getStopItemText(item) {
			let startdate = item.ata ? item.ata : item.etaauth ? item.etaauth : item.eta;
			let enddate = item.atd ? item.atd : item.etdauth ? item.etdauth : item.etd;

			return `${item.num} - ${this.$dateTimeUtils.getLocalFormattedDate(
				startdate,
				'DD/MM/YYYY HH:mm'
			)} - ${this.$dateTimeUtils.getLocalFormattedDate(enddate, 'DD/MM/YYYY HH:mm')} - ${item.status}`;
		},
		afterGetData() {}
	},
	computed: {
		itemsToSelectTypeid() {
			return [{ inconsistencytypeid: this.model.typeid }];
		}
	},
	created() {}
};
</script>
